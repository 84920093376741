<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("ALL") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['items-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i> {{ $t("ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col
              :lg="2"
              :md="6"
              v-if="
                !isPageLoading && _.size(itemTypes) > 0 && fixedItemTypeId == 0
              "
            >
              <FormSelect
                id="item-item-type-input"
                type="select"
                name="itemTypes"
                :options="itemTypesSelect"
                clearable="true"
                v-model="filters.itemTypeId"
                :i18n="{ label: 'ITEM_TYPE' }"
              ></FormSelect>
            </b-col>
            <b-col
              :lg="2"
              :md="6"
              v-if="
                !isPageLoading && _.size(itemTypes) > 0 && fixedItemTypeId == 0
              "
            >
              <FormSelect
                id="item-is-recipe-input"
                type="select"
                name="isRecipe"
                :options="yesNoSelect"
                clearable="true"
                v-model="filters.isRecipe"
                :i18n="{ label: 'IS_RECIPE' }"
              ></FormSelect>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingItem"
          loading-text="Loading... Please wait"
          :sort-by="'code'"
          :sort-desc="false"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['items-update'])"
              :to="{ name: 'edit-item', params: { id: item.id } }"
            >
              {{ item.name }}</router-link
            >
            <template v-else>{{ item.name }}</template>
          </template>

          <template v-slot:item.isRecipe="{ item }">
            <v-chip :color="getColor(item.isRecipe)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsTrue(item.isRecipe) }}</v-icon>
              </v-avatar>
              {{ textIsTrue(item.isRecipe) }}</v-chip
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <ActionButton
              :to="{ name: 'edit-item', params: { id: item.id } }"
              :permissions="['items-update']"
              :label="$t('EDIT')"
              :icon="'mdi-pencil'"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";

//Item Module
import {
  FETCH_ITEMS,
  UPDATE_SINGLE_ITEM
} from "@/modules/item/store/item.module";

//Shared Modules

//Shared Modules
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import _ from "lodash";
export default {
  name: "itemListTable",
  mixins: [permissionMixin],
  props: {
    fixedItemTypeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      itemTypes: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        itemTypeId: null,
        isRecipe: null
      },
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 20,
        totalItems: 100
      },
      rowsPerPageItems: [20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      selectedItemId: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id",
          width: "100px"
        },
        { text: "Код", value: "code" },
        { text: "Име", value: "name" },
        { text: "Тип", value: "itemTypeName" },
        { text: "Рецепта", value: "isRecipe" },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    FormSelect,
    FormSwitch,
    SearchTools,
    ActionButton
  },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Items", route: { name: "list-items" } },
      { title: "Items List" }
    ]);
    if (vm.fixedItemTypeId == 0) {
      vm.getSelects();
    }
  },
  computed: {
    ...mapGetters(["getItems", "isLoadingItem"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        isRecipe: this.filters.isRecipe
      };
    },
    itemTypesSelect() {
      let vm = this;
      let itemTypesSelect = [];
      vm.itemTypes.forEach(function(itemType) {
        let option = {
          id: itemType.id,
          name: vm.$options.filters.capitalize(itemType.name)
        };
        itemTypesSelect.push(option);
      });

      return itemTypesSelect;
    },
    yesNoSelect() {
      let yesNoSelect = [
        {
          id: 0,
          name: "Не"
        },
        {
          id: 1,
          name: "Да"
        }
      ];

      return yesNoSelect;
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset

      if (vm.fixedItemTypeId > 0) {
        params.itemTypeId = vm.fixedItemTypeId;
      } else {
        params.itemTypeId = vm.filters.itemTypeId;
      }

      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_ITEMS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-items" });
          }
        });
    },
    getSelects() {
      let vm = this;
      let payload = {
        item_types: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.itemTypes = data.data.item_types;
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    updateSingleItem(item, column) {
      let vm = this;
      let payload = {
        [column]: item[column]
      };
      this.$store
        .dispatch(UPDATE_SINGLE_ITEM, {
          id: item.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.selectedItemId = null;
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsTrue(active) {
      if (active == false || active == 0) return "Не";
      else return "Да";
    },
    iconIsTrue(value) {
      if (value == false || value == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    }
  }
};
</script>

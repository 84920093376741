var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("ALL"))+" "),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LIST")))])])]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:(['items-create']),expression:"['items-create']"}],staticClass:"btn btn-light-success font-weight-bolder mr-2",attrs:{"to":"add"}},[_c('i',{staticClass:"flaticon2-plus"}),_vm._v(" "+_vm._s(_vm.$t("ADD_NEW")))])],1)]},proxy:true},{key:"footer",fn:function(){return undefined},proxy:true}])},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageLoading),expression:"!isPageLoading"}]},[_c('SearchTools',{attrs:{"searchQuery":_vm.searchQuery},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"do-search":_vm.doSearch,"do-clear":_vm.doClear},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(
              !_vm.isPageLoading && _vm._.size(_vm.itemTypes) > 0 && _vm.fixedItemTypeId == 0
            )?_c('b-col',{attrs:{"lg":2,"md":6}},[_c('FormSelect',{attrs:{"id":"item-item-type-input","type":"select","name":"itemTypes","options":_vm.itemTypesSelect,"clearable":"true","i18n":{ label: 'ITEM_TYPE' }},model:{value:(_vm.filters.itemTypeId),callback:function ($$v) {_vm.$set(_vm.filters, "itemTypeId", $$v)},expression:"filters.itemTypeId"}})],1):_vm._e(),(
              !_vm.isPageLoading && _vm._.size(_vm.itemTypes) > 0 && _vm.fixedItemTypeId == 0
            )?_c('b-col',{attrs:{"lg":2,"md":6}},[_c('FormSelect',{attrs:{"id":"item-is-recipe-input","type":"select","name":"isRecipe","options":_vm.yesNoSelect,"clearable":"true","i18n":{ label: 'IS_RECIPE' }},model:{value:(_vm.filters.isRecipe),callback:function ($$v) {_vm.$set(_vm.filters, "isRecipe", $$v)},expression:"filters.isRecipe"}})],1):_vm._e()]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.options,"items-per-page":_vm.totalItems,"server-items-length":_vm.totalItems,"loading":_vm.isLoadingItem,"loading-text":"Loading... Please wait","sort-by":'code',"sort-desc":false,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: _vm.rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right'
        }},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.getDataFromApi()}]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [(_vm.checkIfUserHasPermission(['items-update']))?_c('router-link',{attrs:{"to":{ name: 'edit-item', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name))]):[_vm._v(_vm._s(item.name))]]}},{key:"item.isRecipe",fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColor(item.isRecipe),"small":"","dark":"","pill":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconIsTrue(item.isRecipe)))])],1),_vm._v(" "+_vm._s(_vm.textIsTrue(item.isRecipe)))],1)]}},{key:"item.actions",fn:function({ item }){return [_c('ActionButton',{attrs:{"to":{ name: 'edit-item', params: { id: item.id } },"permissions":['items-update'],"label":_vm.$t('EDIT'),"icon":'mdi-pencil'}})]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}])})],1),(_vm.isPageLoading)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isPageLoading,"type":"table"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }